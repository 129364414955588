import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
// import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));
const NewPasswordPage = lazy(() => import('src/pages/auth/jwt/new-password'));
const RegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthClassicLayout title="Kartrak: esqueceu a senha">
          <JwtForgotPasswordPage />
        </AuthClassicLayout>
      ),
    },
    { 
      path: 'verify', 
      element:(
        <AuthClassicLayout title="Kartrak: esqueceu a senha">
          <JwtVerifyPage />
        </AuthClassicLayout>
      )
    },
    { 
      path: 'new-password', 
      element: (
        <AuthClassicLayout title="Kartrak: esqueceu a senha">
        <NewPasswordPage />
        </AuthClassicLayout>
        ),
      children: [
        { path: ':token', element: <NewPasswordPage /> },
      ],
    },
    { 
      path: 'register', 
      element: (
        <AuthClassicLayout title="Kartrak: criar usuário">
        <RegisterPage />
        </AuthClassicLayout>
        ),
      children: [
        { path: ':token', element: <RegisterPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
