import { _mock } from './_mock';

// APP
// ----------------------------------------------------------------------

export const _appRelated = ['Chrome', 'Drive', 'Dropbox', 'Evernote', 'Github'].map(
  (name, index) => {
    const system = [2, 4].includes(index) ? 'Windows' : 'Mac';

    const price = [2, 4].includes(index) ? _mock.number.price(index) : 0;

    const shortcut =
      (name === 'Chrome' && '/assets/icons/app/ic_chrome.svg') ||
      (name === 'Drive' && '/assets/icons/app/ic_drive.svg') ||
      (name === 'Dropbox' && '/assets/icons/app/ic_dropbox.svg') ||
      (name === 'Evernote' && '/assets/icons/app/ic_evernote.svg') ||
      '/assets/icons/app/ic_github.svg';

    return {
      id: _mock.id(index),
      name,
      price,
      system,
      shortcut,
      ratingNumber: _mock.number.rating(index),
      totalReviews: _mock.number.nativeL(index),
    };
  }
);

export const _appInstalled = ['Germany', 'England', 'France', 'Korean', 'USA'].map(
  (name, index) => ({
    id: _mock.id(index),
    name,
    android: _mock.number.nativeL(index),
    windows: _mock.number.nativeL(index + 1),
    apple: _mock.number.nativeL(index + 2),
    flag: ['flagpack:de', 'flagpack:gb-nir', 'flagpack:fr', 'flagpack:kr', 'flagpack:us'][index],
  })
);

export const _appAuthors = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  avatarUrl: _mock.image.avatar(index),
  totalFavorites: _mock.number.nativeL(index),
}));

export const _appInvoices = [...Array(5)].map((_, index) => {
  const category = ['Android', 'Mac', 'Windows', 'Android', 'Mac'][index];

  const status = ['paid', 'out of date', 'progress', 'paid', 'paid'][index];

  return {
    id: _mock.id(index),
    invoiceNumber: `INV-199${index}`,
    price: _mock.number.price(index),
    category,
    status,
  };
});

export const _appFeatured = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.postTitle(index),
  description: _mock.sentence(index),
  coverUrl: _mock.image.cover(index),
}));

// ANALYTIC
// ----------------------------------------------------------------------

export const _analyticTasks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.taskNames(index),
}));

export const _analyticPosts = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  postedAt: _mock.time(index),
  title: _mock.postTitle(index),
  coverUrl: _mock.image.cover(index),
  description: _mock.sentence(index),
}));

export const _analyticOrderTimeline = [...Array(5)].map((_, index) => {
  const title = [
    '1983, orders, $4220',
    '12 Invoices have been paid',
    'Order #37745 from September',
    'New order placed #XF-2356',
    'New order placed #XF-2346',
  ][index];

  return {
    id: _mock.id(index),
    title,
    type: `order${index + 1}`,
    time: _mock.time(index),
  };
});

export const _analyticTraffic = [
  {
    value: 'facebook',
    label: 'FaceBook',
    total: _mock.number.nativeL(1),
    icon: 'eva:facebook-fill',
  },
  {
    value: 'google',
    label: 'Google',
    total: _mock.number.nativeL(2),
    icon: 'eva:google-fill',
  },
  {
    value: 'linkedin',
    label: 'Linkedin',
    total: _mock.number.nativeL(3),
    icon: 'eva:linkedin-fill',
  },
  {
    value: 'twitter',
    label: 'Twitter',
    total: _mock.number.nativeL(4),
    icon: 'eva:twitter-fill',
  },
];

// ECOMMERCE
// ----------------------------------------------------------------------

export const _ecommerceSalesOverview = ['Total Profit', 'Total Income', 'Total Expenses'].map(
  (label, index) => ({
    label,
    totalAmount: _mock.number.price(index) * 100,
    value: _mock.number.percent(index),
  })
);

export const _ecommerceBestSalesman = [...Array(5)].map((_, index) => {
  const category = ['CAP', 'Branded Shoes', 'Headphone', 'Cell Phone', 'Earings'][index];

  const flag = ['flagpack:de', 'flagpack:gb-nir', 'flagpack:fr', 'flagpack:kr', 'flagpack:us'][
    index
  ];

  return {
    id: _mock.id(index),
    flag,
    category,
    rank: `Top ${index + 1}`,
    email: _mock.email(index),
    name: _mock.fullName(index),
    totalAmount: _mock.number.price(index),
    avatarUrl: _mock.image.avatar(index + 8),
  };
});

export const _ecommerceLatestProducts = [...Array(5)].map((_, index) => {
  const colors = (index === 0 && ['#2EC4B6', '#E71D36', '#FF9F1C', '#011627']) ||
    (index === 1 && ['#92140C', '#FFCF99']) ||
    (index === 2 && ['#0CECDD', '#FFF338', '#FF67E7', '#C400FF', '#52006A', '#046582']) ||
    (index === 3 && ['#845EC2', '#E4007C', '#2A1A5E']) || ['#090088'];

  return {
    id: _mock.id(index),
    colors,
    name: _mock.productName(index),
    price: _mock.number.price(index),
    coverUrl: _mock.image.product(index),
    priceSale: [1, 3].includes(index) ? _mock.number.price(index) : 0,
  };
});

export const _ecommerceNewProducts = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.productName(index),
  coverUrl: _mock.image.product(index),
}));

// BANKING
// ----------------------------------------------------------------------

export const _bankingContacts = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  email: _mock.email(index),
  avatarUrl: _mock.image.avatar(index),
}));

export const _bankingCreditCard = [
  {
    id: _mock.id(2),
    balance: 23432.03,
    cardType: 'mastercard',
    cardHolder: _mock.fullName(2),
    cardNumber: '**** **** **** 3640',
    cardValid: '11/22',
  },
  {
    id: _mock.id(3),
    balance: 18000.23,
    cardType: 'visa',
    cardHolder: _mock.fullName(3),
    cardNumber: '**** **** **** 8864',
    cardValid: '11/25',
  },
  {
    id: _mock.id(4),
    balance: 2000.89,
    cardType: 'mastercard',
    cardHolder: _mock.fullName(4),
    cardNumber: '**** **** **** 7755',
    cardValid: '11/22',
  },
];

export const _bankingRecentTransitions = [
  {
    id: _mock.id(2),
    name: _mock.fullName(2),
    avatarUrl: _mock.image.avatar(2),
    type: 'Income',
    message: 'Receive money from',
    category: 'Annette Black',
    date: _mock.time(2),
    status: 'progress',
    amount: _mock.number.price(2),
  },
  {
    id: _mock.id(3),
    name: _mock.fullName(3),
    avatarUrl: _mock.image.avatar(3),
    type: 'Expenses',
    message: 'Payment for',
    category: 'Courtney Henry',
    date: _mock.time(3),
    status: 'completed',
    amount: _mock.number.price(3),
  },
  {
    id: _mock.id(4),
    name: _mock.fullName(4),
    avatarUrl: _mock.image.avatar(4),
    type: 'Receive',
    message: 'Payment for',
    category: 'Theresa Webb',
    date: _mock.time(4),
    status: 'failed',
    amount: _mock.number.price(4),
  },
  {
    id: _mock.id(5),
    name: null,
    avatarUrl: null,
    type: 'Expenses',
    message: 'Payment for',
    category: 'Beauty & Health',
    date: _mock.time(5),
    status: 'completed',
    amount: _mock.number.price(5),
  },
  {
    id: _mock.id(6),
    name: null,
    avatarUrl: null,
    type: 'Expenses',
    message: 'Payment for',
    category: 'Books',
    date: _mock.time(6),
    status: 'progress',
    amount: _mock.number.price(6),
  },
];

// BOOKING
// ----------------------------------------------------------------------

export const _bookings = [...Array(5)].map((_, index) => {
  const status = ['Paid', 'Paid', 'Pending', 'Cancelled', 'Paid'][index];

  const customer = {
    avatarUrl: _mock.image.avatar(index),
    name: _mock.fullName(index),
    phoneNumber: _mock.phoneNumber(index),
  };

  const destination = [...Array(5)].map((__, _index) => ({
    name: _mock.tourName(_index + 1),
    coverUrl: _mock.image.travel(_index + 1),
  }))[index];

  return {
    id: _mock.id(index),
    destination,
    status,
    customer,
    checkIn: _mock.time(index),
    checkOut: _mock.time(index),
  };
});

export const _bookingsOverview = [...Array(3)].map((_, index) => ({
  status: ['Pending', 'Canceled', 'Sold'][index],
  quantity: _mock.number.nativeL(index),
  value: _mock.number.percent(index),
}));

export const _bookingReview = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  postedAt: _mock.time(index),
  rating: _mock.number.rating(index),
  avatarUrl: _mock.image.avatar(index),
  description: _mock.description(index),
  tags: ['Great Sevice', 'Recommended', 'Best Price'],
}));

export const _bookingNew = [...Array(5)].map((_, index) => ({
  guests: '3-5',
  id: _mock.id(index),
  bookedAt: _mock.time(index),
  duration: '3 days 2 nights',
  isHot: _mock.boolean(index),
  name: _mock.fullName(index),
  price: _mock.number.price(index),
  avatarUrl: _mock.image.avatar(index),
  coverUrl: _mock.image.travel(index),
}));

export const _consumedMaterials = [
  {
    "total_formula_quantity":100000,
    "total_effective_quantity":101500,
    "gross_deviation":1500,
    "mean_deviation":15,
    "percentage_deviation":0.015,
    "formatted_percentage_deviation":"1.50%",
    "type":"cement",
    "formatted_type":"Cimento"
  },
  {
    "total_formula_quantity":100000,
    "total_effective_quantity":101500,
    "gross_deviation":1500,
    "mean_deviation":15,
    "percentage_deviation":0.015,
    "formatted_percentage_deviation":"1.50%",
    "type":"sand",
    "formatted_type":"Areia"
  },
  {
    "total_formula_quantity":100000,
    "total_effective_quantity":101500,
    "gross_deviation":1500,
    "mean_deviation":-15,
    "percentage_deviation":0.015,
    "formatted_percentage_deviation":"1.50%",
    "type":"gravel",
    "formatted_type":"Brita"
  },
  {
    "total_formula_quantity":100000,
    "total_effective_quantity":98500,
    "gross_deviation":-1500,
    "mean_deviation":-15,
    "percentage_deviation":-0.015,
    "formatted_percentage_deviation":"-1.50%",
    "type":"water",
    "formatted_type":"Água"
  },
  {
    "total_formula_quantity":100000,
    "total_effective_quantity":98500,
    "gross_deviation":-1500,
    "mean_deviation":-0.015,
    "percentage_deviation":-0.015,
    "formatted_percentage_deviation":"-1.50%",
    "type":"additive",
    "formatted_type":"Aditivo"
  },
];

export const _shipments = [
  {
      "quantity": 7.5,
      "contract_code": null,
      "start_time": "2023-10-03T13:10:53",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "2140",
      "seal_number": "81759",
      "fck": 25,
      "slump": "16+-2",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "MUCE",
      "device_name": "MUCE",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 8.0,
      "contract_code": null,
      "start_time": "2023-10-03T13:09:04",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "242",
      "seal_number": "242",
      "fck": 30,
      "slump": "10+-2",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "XCAR",
      "device_name": "XCAR",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 8.5,
      "contract_code": "EWB-0",
      "start_time": "2023-10-03T13:07:43",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "56511696338461",
      "seal_number": "4395361696338461",
      "fck": 30,
      "slump": "100+-20",
      "equipment_plate": "QQV9232",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "HBDO",
      "device_name": "HBDO",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 6.0,
      "contract_code": null,
      "start_time": "2023-10-03T13:07:21",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "dhgfhkhk.j",
      "seal_number": "fdsgfhhg",
      "fck": 10,
      "slump": "10+-2",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "CLTL",
      "device_name": "CLTL",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 6.0,
      "contract_code": null,
      "start_time": "2023-10-03T13:07:06",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "8173",
      "seal_number": "68173",
      "fck": 30,
      "slump": "10+-2",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "SSOR",
      "device_name": "SSOR",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 3.0,
      "contract_code": "ISE-1",
      "start_time": "2023-10-03T13:07:06",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "447281696338423",
      "seal_number": "1190601696338423",
      "fck": 30,
      "slump": "120+-20",
      "equipment_plate": "RLL2A01",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "Superbeton",
      "device_name": "SSJE",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 4.0,
      "contract_code": "WUG-1",
      "start_time": "2023-10-03T13:06:07",
      "canceled_at": null,
      "end_time": "2023-10-03T13:10:51",
      "equipment_ready_time": "2023-10-03T13:06:12",
      "code": "445571696338364",
      "seal_number": "838561696338364",
      "fck": 20,
      "slump": "120+-20",
      "equipment_plate": "MHQ5318",
      "status": "Finalizada",
      "cement_deviation": -9.0,
      "sand_deviation": -33.36000000000013,
      "gravel_deviation": 75.80000000000018,
      "water_deviation": 4.0449072000000115,
      "additive_deviation": -0.07999999999999918,
      "corporation_code": "Superbeton",
      "device_name": "SBNU",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 8.0,
      "contract_code": "999-475/23",
      "start_time": "2023-10-03T13:05:56",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "000004VI6WHTBWKQO0",
      "seal_number": "10822",
      "fck": 20,
      "slump": "12+-2",
      "equipment_plate": "RNZ5B39",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "CASG",
      "device_name": "CASG",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 8.0,
      "contract_code": "999-176/23",
      "start_time": "2023-10-03T13:05:46",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "000004VI6WHSSOWE84",
      "seal_number": "15305",
      "fck": 35,
      "slump": "10+-2",
      "equipment_plate": "FIG7C50",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "FTBU",
      "device_name": "FTBU",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 7.5,
      "contract_code": "OR\u00c7 000547/23",
      "start_time": "2023-10-03T13:04:55",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "FNO007549",
      "seal_number": "0000363",
      "fck": 30,
      "slump": "10+-2",
      "equipment_plate": "DSE4C07",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "BIG",
      "device_name": "BNOD",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 7.0,
      "contract_code": null,
      "start_time": "2023-10-03T13:04:09",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "32502",
      "seal_number": "5037627",
      "fck": 25,
      "slump": "100+-60",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "PMGA",
      "device_name": "PMGA",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 4.5,
      "contract_code": "UQG-0",
      "start_time": "2023-10-03T13:04:08",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "58811696338247",
      "seal_number": "8251696338247",
      "fck": 30,
      "slump": "120+-20",
      "equipment_plate": "AXP6B96",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "MSPE",
      "device_name": "MSPE",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 5.0,
      "contract_code": null,
      "start_time": "2023-10-03T13:01:04",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "3241",
      "seal_number": "4732",
      "fck": 35,
      "slump": "12+-2",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "LARA",
      "device_name": "LARA",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 7.5,
      "contract_code": null,
      "start_time": "2023-10-03T12:57:33",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "23310",
      "seal_number": "18399",
      "fck": 35,
      "slump": "12+-2",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "FAJ3",
      "device_name": "FAJ3",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 2.5,
      "contract_code": "999-1140/23",
      "start_time": "2023-10-03T12:57:05",
      "canceled_at": null,
      "end_time": "2023-10-03T13:06:44",
      "equipment_ready_time": "2023-10-03T13:01:08",
      "code": "000004VI6VYV0O800O",
      "seal_number": "914675",
      "fck": 20,
      "slump": "10+-2",
      "equipment_plate": "AXA9875",
      "status": "Finalizada",
      "cement_deviation": -548.0,
      "sand_deviation": -2309.0,
      "gravel_deviation": -2674.0,
      "water_deviation": -258.0,
      "additive_deviation": -2.5,
      "corporation_code": "CLTL",
      "device_name": "CLTL",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 4.0,
      "contract_code": null,
      "start_time": "2023-10-03T12:55:01",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "5641367",
      "seal_number": "5641367",
      "fck": 20,
      "slump": "16+-3",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "POTG",
      "device_name": "POTG",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
  {
      "quantity": 10.0,
      "contract_code": "KWH-0",
      "start_time": "2023-10-03T12:54:59",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "447251696337698",
      "seal_number": "1382111696337698",
      "fck": 30,
      "slump": "100+-20",
      "equipment_plate": "RYK3C91",
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "Superbeton",
      "device_name": "SFLN",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 7.0,
      "contract_code": null,
      "start_time": "2023-10-03T12:54:53",
      "canceled_at": null,
      "end_time": null,
      "equipment_ready_time": null,
      "code": "0310'",
      "seal_number": "0310'",
      "fck": 30,
      "slump": "23+-3",
      "equipment_plate": null,
      "status": "Em andamento",
      "cement_deviation": null,
      "sand_deviation": null,
      "gravel_deviation": null,
      "water_deviation": null,
      "additive_deviation": null,
      "corporation_code": "BIG",
      "device_name": "BATB",
      "construction_name": "Teste",
      "accepted_deviation": true
  },
  {
      "quantity": 8.0,
      "contract_code": "OR\u00c7 000016/21",
      "start_time": "2023-10-03T12:54:50",
      "canceled_at": null,
      "end_time": "2023-10-03T13:03:30",
      "equipment_ready_time": "2023-10-03T12:57:07",
      "code": "FNO000275",
      "seal_number": "0000378",
      "fck": 25,
      "slump": "10+-2",
      "equipment_plate": "EVI8F35",
      "status": "Finalizada",
      "cement_deviation": 8.0,
      "sand_deviation": -7.1599999999998545,
      "gravel_deviation": -314.0,
      "water_deviation": 11.177599999999984,
      "additive_deviation": 0.009999999999999787,
      "corporation_code": "BIG",
      "device_name": "BNOD",
      "construction_name": "Teste",
      "accepted_deviation": false
  },
]

export const _devices = [
  {
     "device_uin":"9b4c61ad89bb1ace688b07d57919b5aa",
     "id":58,
     "name":"ARAB",
     "corporation_code":"ARAB"
  },
  {
     "device_uin":"8d5a5d76bc9e70cf3dc99e361a80ed76",
     "id":59,
     "name":"ARSC",
     "corporation_code":"ARSC"
  },
  {
     "device_uin":"a931175acfd07c2c9d0e997245136f18",
     "id":55,
     "name":"ATFE",
     "corporation_code":"ATFE"
  },
  {
     "device_uin":"cfca616e11c16d1d21f46394a62d645b",
     "id":9,
     "name":"BATB",
     "corporation_code":"BIG"
  },
  {
     "device_uin":"25f38c5a27fa8a5314be393e9cfb0d1f",
     "id":53,
     "name":"BCAM",
     "corporation_code":"BIG"
  },
  {
     "device_uin":"bd53e81cc479d7df74e660c82f20825a",
     "id":65,
     "name":"BICA",
     "corporation_code":"BICA"
  },
  {
     "device_uin":"993df763296a0c4bcc31fb61615f3ec0",
     "id":7,
     "name":"BJND",
     "corporation_code":"BIG"
  },
  {
     "device_uin":"886876b04ac24e6c1b6690bdd8fd4ae6",
     "id":35,
     "name":"BNOD",
     "corporation_code":"BIG"
  },
  {
     "device_uin":"069ba42bf869f0911c58650e63073b68",
     "id":12,
     "name":"BSJO",
     "corporation_code":"BIG"
  },
  {
     "device_uin":"efb3c5e1fa1ff5473116c6d42102676b",
     "id":10,
     "name":"BVAR",
     "corporation_code":"BIG"
  },
  {
     "device_uin":"73a45e8e6589c41a6324b1d1cf1096bf",
     "id":43,
     "name":"CACA",
     "corporation_code":"CACA"
  },
  {
     "device_uin":"3eda328edf12b720986366cbb2e56be1",
     "id":52,
     "name":"CASG",
     "corporation_code":"CASG"
  },
  {
     "device_uin":"d503f806891b2e4552ff50a9a6321af2",
     "id":49,
     "name":"CBJE",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"5304da199cf3eedca3be6626797fa874",
     "id":24,
     "name":"CBTM",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"4d2372b714702ca620ddb71b1222cc00",
     "id":34,
     "name":"CDIA",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"499308f6f00002fb9642c3e9e1d7a94f",
     "id":31,
     "name":"CIBA",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"113ec2e269e28075f8b9af875bc16c47",
     "id":28,
     "name":"CLTL",
     "corporation_code":"CLTL"
  },
  {
     "device_uin":"6b8762457014f0633ed3623568f7a4f0",
     "id":47,
     "name":"CMAT",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"43f293342aa6027b117d0f6763144f45",
     "id":50,
     "name":"CNFR",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"b5285439fbebb162f9a022fcc723da92",
     "id":36,
     "name":"COSA",
     "corporation_code":"COSA"
  },
  {
     "device_uin":"8e417e7e4acd4c6eae16899af2eac37a",
     "id":37,
     "name":"COSO",
     "corporation_code":"COSO"
  },
  {
     "device_uin":"2c0282f589b6b27aebfae70c3f9eb58c",
     "id":46,
     "name":"CPAL",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"6c62f9f7eac014a0ddeab4f100b5c427",
     "id":63,
     "name":"CSER",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"70e6141fd65a7bf76590776f23c8e1e3",
     "id":64,
     "name":"CSJM",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"90b106b956596589d6f98d61c85ace55",
     "id":30,
     "name":"CVCA",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"975ffd153a39cc69c6b346a209848b66",
     "id":26,
     "name":"CVIV",
     "corporation_code":"Concrelagos"
  },
  {
     "device_uin":"7803f13c20db629d9a3ce977a7e81690",
     "id":66,
     "name":"DEMO",
     "corporation_code":"DEMO"
  },
  {
     "device_uin":"674ecb1a52ac0851035ca0ed9a0f335f",
     "id":69,
     "name":"DEMO-R4",
     "corporation_code":"DEMO-R4"
  },
  {
     "device_uin":"7b3e667e807848a1c3681206da71f5da",
     "id":18,
     "name":"FAJ2",
     "corporation_code":"FAJ2"
  },
  {
     "device_uin":"803d77487cfc672d9d2a77a1f1adcd96",
     "id":19,
     "name":"FAJ3",
     "corporation_code":"FAJ3"
  },
  {
     "device_uin":"12035bda205a18571d92a896e29a7a14",
     "id":16,
     "name":"FCIA",
     "corporation_code":"FCIA"
  },
  {
     "device_uin":"359bbf184c483ec09aacf5cdb08b8b79",
     "id":8,
     "name":"FTBU",
     "corporation_code":"FTBU"
  },
  {
     "device_uin":"bea3eab356d82d42ee2702b667adc168",
     "id":15,
     "name":"FUMU",
     "corporation_code":"FUMU"
  },
  {
     "device_uin":"c0408ba9f545ff93305479a47641cb20",
     "id":45,
     "name":"HBDO",
     "corporation_code":"HBDO"
  },
  {
     "device_uin":"0de8734056171ac8b3e42020c8ca7656",
     "id":41,
     "name":"HBTM",
     "corporation_code":"HBTM"
  },
  {
     "device_uin":"232195d3b9a9263c63849807ba783f2e",
     "id":22,
     "name":"HMGV",
     "corporation_code":"HMGV"
  },
  {
     "device_uin":"4756ebefb761e68d657aceae79262b2a",
     "id":48,
     "name":"HMMC",
     "corporation_code":"HMMC"
  },
  {
     "device_uin":"5dc332548115414897f14f6f85d729a7",
     "id":33,
     "name":"LARA",
     "corporation_code":"LARA"
  },
  {
     "device_uin":"e0ec234520556c98fd82cca139a355c6",
     "id":62,
     "name":"LSPA",
     "corporation_code":"Lagomix"
  },
  {
     "device_uin":"6de20576755116f6b57e0a2d2c33a7e4",
     "id":38,
     "name":"LULU",
     "corporation_code":"LULU"
  },
  {
     "device_uin":"af63af37e9d6a749851491203a9f5078",
     "id":39,
     "name":"LUSO",
     "corporation_code":"LUSO"
  },
  {
     "device_uin":"221e95e0e00f8a6133675073256b42b7",
     "id":27,
     "name":"MMJL",
     "corporation_code":"MMJL"
  },
  {
     "device_uin":"255450551e6d9b97a41dff30d94bb5c1",
     "id":56,
     "name":"MSPE",
     "corporation_code":"MSPE"
  },
  {
     "device_uin":"496df9fc3c10d040bbbd7ba4f8658c38",
     "id":14,
     "name":"MUBA",
     "corporation_code":"MUBA"
  },
  {
     "device_uin":"2e06f63b6e0cdeaa11c72cef542ca23d",
     "id":42,
     "name":"MUCE",
     "corporation_code":"MUCE"
  },
  {
     "device_uin":"6f202ee0a22cc295f08d0fdee9d22ddf",
     "id":40,
     "name":"MULU",
     "corporation_code":"MULU"
  },
  {
     "device_uin":"ccada33083aa53da1d6035c3ea2872a8",
     "id":21,
     "name":"MVRB",
     "corporation_code":"MVRB"
  },
  {
     "device_uin":"492c09134b254a9fabdddea2eb1b59bb",
     "id":25,
     "name":"MVSA",
     "corporation_code":"MVSA"
  },
  {
     "device_uin":"d99d7dbf20cb08b311fabd36ba585db3",
     "id":17,
     "name":"PMGA",
     "corporation_code":"PMGA"
  },
  {
     "device_uin":"08f2f98b42c773695ab2e3b94de16dc1",
     "id":11,
     "name":"POTG",
     "corporation_code":"POTG"
  },
  {
     "device_uin":"105b4bf945034e6432632e487abe3fdd",
     "id":20,
     "name":"PRRP",
     "corporation_code":"PRRP"
  },
  {
     "device_uin":"ccd14c5e0e63faeea114b19854b3c417",
     "id":68,
     "name":"SBAR",
     "corporation_code":"SBAR"
  },
  {
     "device_uin":"3bc56e83c6e9dba4e0dfed383f7cbbb5",
     "id":2,
     "name":"SBNU",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"a13d6a9c2dd235a569b166e268a966d8",
     "id":3,
     "name":"SBQE",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"15a8fa3222d2796640e6546285f9dafb",
     "id":4,
     "name":"SFLN",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"af46a25ea7bfb57609c06e3202f73a06",
     "id":57,
     "name":"SFLN",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"ac28037187121f384946537dabce0294",
     "id":32,
     "name":"SGBA",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"03f68d358484bb8cd7574db380169188",
     "id":5,
     "name":"SIDL",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"0d5c2ef24eed1ddcefd654d909dfdfc9",
     "id":6,
     "name":"SIPA",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"c5cdbd8d92b48b656764347eddc999b5",
     "id":1,
     "name":"SNVT",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"1d298b2b4165452ea134f845f2c44565",
     "id":29,
     "name":"SNVT2",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"67daee9b6b5028f896c0e0c7dd2348d3",
     "id":54,
     "name":"SSJB",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"84a3fe2fad0f9c771bba2a4c1a907ddd",
     "id":13,
     "name":"SSJE",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"51359e5363d94cc1502c83709e6ba4cf",
     "id":23,
     "name":"SSOR",
     "corporation_code":"SSOR"
  },
  {
     "device_uin":"314376895ae51089cb7a8dc23b5871fa",
     "id":44,
     "name":"SUCO",
     "corporation_code":"SUCO"
  },
  {
     "device_uin":"5cbb4c2b6476487e6307416377c15244",
     "id":51,
     "name":"SVN3",
     "corporation_code":"Superbeton"
  },
  {
     "device_uin":"01cb3a3d285cca7b7fb111a110727439",
     "id":61,
     "name":"TESTS",
     "corporation_code":"tests"
  },
  {
     "device_uin":"049f06b3c5d8ccd8dc29fef8bed0c0b6",
     "id":60,
     "name":"TSJB",
     "corporation_code":"tests"
  },
  {
     "device_uin":"fbf42e9c20c153709be38bc27eefb781",
     "id":67,
     "name":"XCAR",
     "corporation_code":"XCAR"
  }
]

export const _volume_travels_by_day = [
  {
      "date":"2023-09-09",
      "volume":112.0,
      "travels":14
  },
  {
      "date":"2023-09-11",
      "volume":6831.299999999999,
      "travels":963
  },
  {
      "date":"2023-09-12",
      "volume":6623.899999999999,
      "travels":938
  },
  {
      "date":"2023-09-13",
      "volume":6517.199999999999,
      "travels":935
  },
  {
      "date":"2023-09-14",
      "volume":7390.7,
      "travels":1017
  },
  {
      "date":"2023-09-15",
      "volume":6987.999999999999,
      "travels":975
  },
  {
      "date":"2023-09-16",
      "volume":2630.5,
      "travels":391
  },
  {
      "date":"2023-09-18",
      "volume":5807.3,
      "travels":834
  },
  {
      "date":"2023-09-19",
      "volume":6259.1,
      "travels":910
  },
  {
      "date":"2023-09-20",
      "volume":5978.299999999999,
      "travels":874
  },
  {
      "date":"2023-09-21",
      "volume":6893.6,
      "travels":1007
  },
  {
      "date":"2023-09-22",
      "volume":7154.9,
      "travels":1035
  },
  {
      "date":"2023-09-23",
      "volume":2626.1000000000004,
      "travels":380
  },
  {
      "date":"2023-09-25",
      "volume":5696.400000000001,
      "travels":827
  },
  {
      "date":"2023-09-26",
      "volume":5544.9,
      "travels":819
  },
  {
      "date":"2023-09-27",
      "volume":6109.2,
      "travels":883
  },
  {
      "date":"2023-09-28",
      "volume":5507.400000000001,
      "travels":807
  },
  {
      "date":"2023-09-29",
      "volume":6196.300000000001,
      "travels":903
  },
  {
      "date":"2023-09-30",
      "volume":2510.1,
      "travels":364
  },
  {
      "date":"2023-10-02",
      "volume":4953.799999999999,
      "travels":726
  },
  {
      "date":"2023-10-03",
      "volume":6507.599999999999,
      "travels":941
  },
  {
      "date":"2023-10-04",
      "volume":4951.499999999999,
      "travels":756
  },
  {
      "date":"2023-10-05",
      "volume":5609.599999999999,
      "travels":819
  },
  {
      "date":"2023-10-06",
      "volume":6141.000000000001,
      "travels":874
  },
  {
      "date":"2023-10-07",
      "volume":2447.5,
      "travels":359
  },
  {
      "date":"2023-10-08",
      "volume":10.5,
      "travels":1
  },
  {
      "date":"2023-10-09",
      "volume":3995.1000000000004,
      "travels":625
  }
]

export const _volume_and_travels_by_formula = [
  {
     "volume":330.0,
     "formula_code":"TR-468",
     "fck":30,
     "slump":"120+-20",
     "travels":53
  },
  {
     "volume":88.0,
     "formula_code":"TR-473",
     "fck":30,
     "slump":"220+-30",
     "travels":11
  },
  {
     "volume":87.0,
     "formula_code":"TR-462",
     "fck":30,
     "slump":"120+-20",
     "travels":13
  },
  {
     "volume":56.0,
     "formula_code":"TR-497",
     "fck":30,
     "slump":"120+-20",
     "travels":6
  },
  {
     "volume":53.5,
     "formula_code":"TR-461",
     "fck":25,
     "slump":"120+-20",
     "travels":10
  },
  {
     "volume":34.0,
     "formula_code":"TR-463",
     "fck":35,
     "slump":"120+-20",
     "travels":6
  },
  {
     "volume":25.5,
     "formula_code":"TR-461",
     "fck":25,
     "slump":"160+-30",
     "travels":3
  },
  {
     "volume":20.0,
     "formula_code":"TR-1200",
     "fck":10,
     "slump":"140+-20",
     "travels":3
  },
  {
     "volume":8.0,
     "formula_code":"TR-467",
     "fck":25,
     "slump":"120+-20",
     "travels":1
  },
  {
     "volume":6.0,
     "formula_code":"TR-465",
     "fck":20,
     "slump":"120+-20",
     "travels":1
  },
  {
     "volume":5.0,
     "formula_code":"TR-469",
     "fck":35,
     "slump":"120+-20",
     "travels":1
  }
]

// export const _manual_operations = [
//   // conciliação incompleta (pending) -> tem itens conciliados e itens pendentes
//   // conciliação divergente (divergent) -> a soma das quantidades não batem



//   // aguardando conciliação (pending) -> todos os itens pendentes -> se nao tem user id
//   // conciliado (reconciled)-> todos os sensors possuem item e location e pode ser feita baixa de estoque -> todos os sensor variations tem user id

//   // botao conciliar estoque -> conciliar |+ alternar acao do botao conforme status, conciliar / desconciliar
//   // se conciliado sumir os botoes de edição

//   {
//     "device_name": "LULU",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "pending",
//     "sensor_variations": [
//     {
//       "id":1,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location": {
//         "location_id": 37,
//         "location_name": "Silo 1"
//       },
//       "item": {
//         "item_id": 35,
//         "item_name": "Cimento CP-V"
//       },
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     },
//     {
//       "id":2,
//       "sensor_name": "Balança de Agregados",
//       "quantity": 23456.00,
//       "location": {
//         "location_id": 33,
//         "location_name": "Baia de Brita 0"
//       },
//       "item": {
//         "item_id": 32,
//         "item_name": "Brita 0"
//       },
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     },
//     {
//       "id":3,
//       "sensor_name": "Balança de Agregados",
//       "quantity": 23456.00,
//       "location": {

//       },
//       "item": {

//       },
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   },
//   {
//     "device_name": "LULU",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "reconciled",
//     "sensor_variations": [
//     {
//       "id":1,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location": {
//         "location_id": 37,
//         "location_name": "Silo 1"
//       },
//       "item": {
//         "item_id": 35,
//         "item_name": "Cimento CP-V"
//       },
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     },
//     {
//       "id":2,
//       "sensor_name": "Balança de Agregados",
//       "quantity": 23456.00,
//       "location": {
//         "location_id": 33,
//         "location_name": "Baia de Brita 0"
//       },
//       "item": {
//         "item_id": 32,
//         "item_name": "Brita 0"
//       },
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     },
//     {
//       "id":3,
//       "sensor_name": "Balança de Agregados",
//       "quantity": 23456.00,
//       "location": {

//       },
//       "item": {

//       },
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   },
//   {
//     "device_name": "LULU",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "completed",
//     "sensor_variations": [
//     {
//       "id":6,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location_name": "Silo 1",
//       "item_name": "Cimento CP-V",
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   },
//   {
//     "device_name": "LULUadsadsa",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "completed",
//     "sensor_variations": [
//     {
//       "id":7,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location_name": "Silo 1",
//       "item_name": "Cimento CP-V",
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   },
//   {
//     "device_name": "XCAR",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "completed",
//     "sensor_variations": [
//     {
//       "id":8,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location_name": "Silo 1",
//       "item_name": "Cimento CP-V",
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   },
//   {
//     "device_name": "XCAR",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "completed",
//     "sensor_variations": [
//     {
//       "id":9,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location_name": "Silo 1",
//       "item_name": "Cimento CP-V",
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   },
//   {
//     "device_name": "XCAR",
//     "start_time": "2023-10-03T13:04:09",
//     "end_time": "2023-10-03T13:04:09",
//     "code": "000004UZ7K2INMASKG",
//     "seal_number": "34143",
//     "status": "completed",
//     "sensor_variations": [
//     {
//       "id":10,
//       "sensor_name": "Balança de Cimento",
//       "quantity": 23456.00,
//       "location_name": "Silo 1",
//       "item_name": "Cimento CP-V",
//       "updated_by": "Victor Lopes",
//       "updated_at": "2023-10-03T13:04:09"
//     }
//     ]
//   }
// ]
// export const _manual_operations =
// [
//   {
//     "start_time": "2023-11-03T19:15:52.785419",
//     "end_time": "2023-11-03T19:15:52.785428",
//     "concrete_shipment_id": null,
//     "id": 2719,
//     "status": "pending",
//     "device_id": 61,
//     "device_name": "TESTS",
//     "device_uin": "01cb3a3d285cca7b7fb111a110727439",
//     "code": null,
//     "seal_number": null,
//     "sensor_variations": [
//       {
//         "parent_id": null,
//         "manual_operation_id": 2719,
//         "sensor_name": "cement_scale",
//         "user_id": null,
//         "inventory_item_id": null,
//         "id": 9276,
//         "quantity": 0.1,
//         "updated_at": null,
//         "inventory_location_id": null,
//         "location_name": null,
//         "location_code": null,
//         "location_id": null,
//         "item_name": null,
//         "item_code": null,
//         "item_id": null,
//         "updated_by": null
//       },
//       {
//         "parent_id": 9276,
//         "manual_operation_id": 2719,
//         "sensor_name": "cement_scale",
//         "user_id": 1,
//         "inventory_item_id": 524,
//         "id": 9279,
//         "quantity": 0.1,
//         "updated_at": "2023-11-07T10:01:54.234605",
//         "inventory_location_id": 535,
//         "location_name": "Silo 1",
//         "location_code": "SCIM11",
//         "location_id": 535,
//         "item_name": "Cimento1",
//         "item_code": "CIM1",
//         "item_id": 524,
//         "updated_by": "Kartrak Admin"
//       }
//     ]
//   }
// ]

export const _inventory_items = [
    {"id":30,"dev_id":6,"name":"MIRA RT 101","code":"bcd52bfb-d28d-43ef-9db2-93a8464d392f","device_id":5,"type":"additive"},
    {"id":31,"dev_id":1,"name":"Brita 1","code":"676dc8e3-470a-470a-ae02-52fd409c159d","device_id":5,"type":"gravel"},
    {"id":32,"dev_id":2,"name":"Brita 0","code":"ed9a1fb4-42a4-4354-bdbe-1fa29f5bf078","device_id":5,"type":"gravel"},
    {"id":33,"dev_id":3,"name":"Areia Artificial","code":"7d0bd632-5f3b-4163-bc00-cdf2699b91aa","device_id":5,"type":"sand"},
    {"id":34,"dev_id":4,"name":"Areia Natural","code":"9492a5ed-533c-4474-a4f8-a152d358a0f3","device_id":5,"type":"sand"},
    {"id":35,"dev_id":5,"name":"Cimento CP-V","code":"e5e99f8f-a4f4-4a72-95da-943a635e66e7","device_id":5,"type":"cement"},
    {"id":36,"dev_id":7,"name":"Água","code":"f9387051-5c90-4b7d-a801-6bed700bde1e","device_id":5,"type":"water"}
]

export const _inventory_locations = [
  {"id":33,"dev_id":1,"name":"Baia de Brita 0","code":"98d6d255-f7ac-4230-a067-e1e2c903058e","device_id":5},
  {"id":34,"dev_id":2,"name":"Baia de Brita 1","code":"98d6d24e-6da6-40d5-bd7a-f3f81a2615f5","device_id":5},
  {"id":35,"dev_id":4,"name":"Baia de Areia Artificial","code":"98d6d265-4096-4c83-9308-94029765d0db","device_id":5},
  {"id":36,"dev_id":3,"name":"Baia de Areia Natural","code":"98d6d279-b42c-4bbf-b628-6693a9c2e68f","device_id":5},
  {"id":37,"dev_id":7,"name":"Silo 1","code":"98ce2b59-33e6-44cf-8638-811369e1cd9e","device_id":5},
  {"id":38,"dev_id":8,"name":"Silo 2","code":"98ce2b5c-7983-42d9-b555-efbc87db55e2","device_id":5},
  {"id":39,"dev_id":6,"name":"Tanque de Aditivos","code":"98d6d288-180e-445d-844c-7efdb8d91e73","device_id":5},
  {"id":40,"dev_id":5,"name":"Caixa da Água","code":"98d6d294-5dc0-4b5a-971c-b562ed752d84","device_id":5}
  ]
