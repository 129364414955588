import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// layouts
import PublicLayout from 'src/layouts/public';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/404'));

// SHIPMENT PAGE
const ShipmentDetailsPage = lazy(() => import('src/pages/public/shipment/details'));

// ----------------------------------------------------------------------

export const publicRoutes = [
  {
    path: 'public',
    element: (
      <PublicLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </PublicLayout>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'shipment',
        children: [
          { element: <IndexPage />, index: true },
          { path: ':id', element: <ShipmentDetailsPage /> },
        ],
      },
    ],
  },
];
