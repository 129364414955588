// ErrorProvider.js

import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [errorMsg, setErrorMsg] = useState('');

  const setErrorMessage = useCallback((message) => {
    setErrorMsg(message);
  }, []);

  const clearErrorMessage = useCallback(() => {
    setErrorMsg('');
  }, []);

  const contextValue = useMemo(() => ({
    errorMsg,
    setErrorMessage, // Add this line to include setErrorMessage in the context
    clearErrorMessage,
  }), [errorMsg, setErrorMessage, clearErrorMessage]);

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PropTypes.node,
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};
