import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// SHIPMENT PAGE
const ShipmentDetailsPage = lazy(() => import('src/pages/dashboard/shipment/details'));
// MANUAL OPERATIONS PAGE
const ManualOperationsListPage = lazy(() => import('src/pages/dashboard/manual_operations/list'));

// USER PAGE
// const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
// const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'shipment',
        children: [
          { element: <IndexPage />, index: true },
          { path: ':id', element: <ShipmentDetailsPage /> },
        ],
      },
      {
        path: 'manual_operations',
        children: [
          { element: <ManualOperationsListPage />, index: true },
        ],
      },
    ],
  },
];
