import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { useError } from '../settings/context/error-provider';

const AutoHideAlert = () => {
  const {errorMsg, setErrorMessage} = useError();

  useEffect(() => {
    const errorTimer = setTimeout(() => {
      setErrorMessage('');
    }, 7000);

    return () => clearTimeout(errorTimer);
  }, [errorMsg, setErrorMessage]);

  return (
    <div style={{ position: 'fixed', bottom: '50px', right: '2rem', zIndex: 1000 }}>
      {errorMsg && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMsg}
        </Alert>
      )}
    </div>
  );
};

export default AutoHideAlert;
