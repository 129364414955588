import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SWRConfig } from 'swr';

//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

const swrConfig = {
  revalidateOnFocus: true,
};

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <SWRConfig value={swrConfig}>
          <App />
        </SWRConfig>,
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
